import { AddToCartType, SaleChannel } from '@/constants'
import { useLocalStorage } from '@/hooks'
import { useCookie } from '@/hooks/useCookie'
import type { BannerList, QueryParam, Region, Store } from '@/types'
import {
  type Cart,
  type CartItemType,
  type Installment,
  type User,
  type UserAddress,
} from '@/types'
import { api } from '@/utils'
import { getShippingAddress, getShippingAddressDetail } from '@/utils/endpoint'
import { convertQueryParam } from '@/utils/next-util'
import constate from 'constate'
import { deleteCookie, getCookie, setCookie } from 'cookies-next'
import { isEmpty } from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

type Props = {
  authToken: string | null
}
interface GlobalError {
  status: boolean
  message: string
  key: string
}
const Container = (props: Props) => {
  const initialCart: Cart = {
    amount: 0,
    products: [],
    coupons: [],
    quantity: 0,
    serviceFee: 0,
    shippingAndInstallFee: 0,
    saleAmount: 0,
    shippingFee: 0,
    discount: 0,
    promotionAmount: 0,
  }
  const GlobalErrorProps: GlobalError = {
    status: false,
    message: '',
    key: '',
  }
  const initialInstallment: Installment = {
    amount: 0,
    products: [],
    quantity: 0,
    serviceFee: 0,
    saleAmount: 0,
    shippingFee: 0,
    discount: 0,
    promotionAmount: 0,
  }
  const router = useRouter()
  const [cart, setCart] = useLocalStorage<Cart>('cart', initialCart)
  const [user, setUser] = useState<User | null>(null)
  const [token, setToken] = useCookie<string | null>(
    'token',
    props.authToken || null
  )
  const [storeList, setStoreList] = useState<Store[] | null>(null)
  const [listBranch, setListBranch] = useState<Store[] | null>(null)
  const [storeSelected, setStoreSelected] = useState<Store | null>(null)
  const [userAddress, setUserAddress] = useState<UserAddress | null>(null)
  const [installment, setInstallment] = useLocalStorage<Installment>(
    'installment',
    initialInstallment
  )
  const [voucherApply, setVoucherApply] = useLocalStorage<string[]>(
    'applyVoucher',
    []
  )

  const [address, setAddress] = useState<UserAddress | null | undefined>()
  const [listAddress, setListAddress] = useState<
    UserAddress[] | null | undefined
  >()
  const [listAddressByRegion, setListAddressByRegion] = useState<
    UserAddress[] | null | undefined
  >()
  const [listAddressReduce, setListAddressReduce] = useState<
    UserAddress[] | null | undefined
  >()
  const [isOpenListAddress, setIsOpenListAddress] = useState(false)
  const [isOpenAddAddress, setIsOpenAddAddress] = useState(false)
  const [globalLoading, setGlobalLoading] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [remember, setRemember] = useCookie('remember', true)
  const [channel, setChannel] = useCookie('channel', SaleChannel.B2C)
  const [provinceOfRegion, setProvinceOfRegion] = useState<Region[] | null>(
    null
  )
  const currentRegion = getCookie('Area-Code')

  const [accessToken, setAccessToken] = useCookie(
    'access',
    process.env.NEXT_PUBLIC_ACCESS_TOKEN
  )
  const [destinationCode, setDestinationCode] = useCookie<string | null>(
    'destinationCode',
    null
  )
  const [popupMain, setPopupMain] = useState<BannerList | undefined>()
  const [errorAuth, setErrorAuth] = useState<GlobalError | null>(
    GlobalErrorProps
  )
  const [clientIP, setClientIP] = useState<string | null>(null)
  // const [sourceClid, setSourceClid] = useState('')
  const getProvince = async () => {
    const res = await api(`api/sale-region/province`, {
      baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
      method: 'get',
    })

    if (res && !(res instanceof Error)) {
      setProvinceOfRegion(res.data)
      return
    }
  }
  useEffect(() => {
    getProvince()
  }, [])
  useEffect(() => {
    api(`api/area?type=shipping&level=2`, {
      method: 'get',
      baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    }).then(async result => {
      if (result instanceof Error) {
        return
      }
      if (provinceOfRegion) {
        const mappingAddress = result?.data?.map((itemA: UserAddress) => {
          const matchB = provinceOfRegion.find(
            itemB => itemB.code === itemA.code
          )

          return {
            ...itemA,
            saleRegion: matchB?.parentCode || '',
          }
        })
        setListAddressReduce(mappingAddress)
      }
    })
  }, [provinceOfRegion])

  useEffect(() => {
    if (token && getCookie('channel')) {
      const channelData = getCookie('channel')
      setChannel(channelData as SaleChannel)
    }
  }, [token])
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await fetch('/api/client-ip')
        const data = await response.json()
        setClientIP(data.clientIP)
      } catch (error) {
        console.error('Error fetching IP:', error)
      }
    }
    fetchIP()
  }, [router])

  const logout = async () => {
    setUser(null)
    setRemember(false)
    setToken(null)
    setAddress(null)
    await deleteCookie('token')
    await deleteCookie('channel')
    localStorage?.clear()
    await localStorage?.setItem('Area-Code', 'true')
    await window.location.reload()
  }

  const getGuessTokenCart = () => {
    if (token) {
      return null
    }
    let guestId = getCookie('guestId')
    if (guestId) {
      return guestId
    }
    guestId = uuidv4().replaceAll('-', '')
    const expiryDate = new Date()
    expiryDate.setMonth(expiryDate.getMonth() + 1)
    setCookie('guestId', guestId, { expires: expiryDate })
    return guestId
  }

  const fetchProfile = async () => {
    const res = await api(`api/profile`, {
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    })
    if (res && !(res instanceof Error)) {
      setUser(res.data)
      return
    }
    await logout()
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const fetchCart = async (
    query: {
      wardCode?: string
      shippingMethod?: string
      coupon?: string | string[]
      isPreOrder?: boolean
      productIds?: string
      action?: string
      type?: string
    } = {}
  ) => {
    let params = null

    const queryParams = {
      ...(query.wardCode || destinationCode
        ? { destinationCode: query.wardCode || destinationCode }
        : {}),
      ...(query.shippingMethod ? { shippingMethod: query.shippingMethod } : {}),
      ...(query.coupon ? { coupon: query.coupon } : {}),
      ...(query.isPreOrder ? { isPreOrder: query.isPreOrder } : {}),
      ...(router?.query?.type == AddToCartType.PRE_ORDER
        ? { isPreOrder: true }
        : {}),
      ...(query.productIds ? { productIds: query.productIds } : {}),
      ...(router?.query?.productIds
        ? { productIds: router?.query?.productIds }
        : {}),
    }
    if (!isEmpty(queryParams) && queryParams) {
      params = convertQueryParam(queryParams as QueryParam)
    }
    const token = getGuessTokenCart()
    const res = await api(`api/shopping-cart?${params || ''}`, {
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
      ...(token ? { headers: { uuid: token } } : {}),
    })
    if (res instanceof Error) {
      setErrorAuth({ status: true, message: res.message, key: 'fetchCart' })
      throw res
    }
    setCart({
      ...res.data,
      quantity: res?.data?.products?.reduce(
        (acc: number, e: CartItemType) => acc + e?.quantity,
        0
      ),
    })
    return res.data
  }
  const fetchAddress = async () => {
    // const ids = router?.query?.shippingAddress as string
    try {
      const listProvinceAvailable = provinceOfRegion
        ?.filter(e => e.parentCode === currentRegion)
        .map(item => item.code)
        .join(',')

      const queryParams = new URLSearchParams()
      if (listProvinceAvailable) {
        queryParams.append('provinceCode', listProvinceAvailable)
        queryParams.append('type', 'shipping')
      }

      const res = await api(`api/user-contact?${queryParams.toString()}`, {
        method: 'GET',
        baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
      })

      if (
        res &&
        !(res instanceof Error) &&
        res.data?.length > 0 &&
        provinceOfRegion
      ) {
        const mappingAddress = res?.data?.map((itemA: UserAddress) => {
          const matchB = provinceOfRegion?.find(
            itemB => itemB.code === itemA.provinceCode
          )
          return {
            ...itemA,
            saleRegion: matchB?.parentCode || '',
          }
        })
        const defaultAddress = res?.data.find(
          (e: UserAddress) => e.default && e?.provinceCode == currentRegion
        )

        if (router?.query?.shippingAddress) {
          const curr = mappingAddress.find(
            (e: UserAddress) =>
              e?._id == router?.query?.shippingAddress &&
              e?.provinceCode == currentRegion
          )
          setUserAddress(curr)
        } else {
          setUserAddress(
            defaultAddress ||
              res?.data.find(
                (e: UserAddress) => e?.provinceCode == currentRegion
              )
          )
        }
        setListAddressByRegion(mappingAddress)
      }
    } catch (error) {
      console.error('Error fetching address:', error)
      await logout()
    }
  }

  const fetchPopup = async (): Promise<BannerList | undefined> => {
    const res = await api(`api/banners?type=fullPopup`, {
      baseURL: process.env.NEXT_PUBLIC_MARKETING_API_URL,
      method: 'get',
    })
    if (res && !(res instanceof Error)) {
      setPopupMain(res?.data.data[0])
    }
    return
  }

  useEffect(() => {
    if (props.authToken && !token) {
      setToken(props.authToken)
    }
  }, [props.authToken])

  useEffect(() => {
    fetchPopup()
  }, [])

  useEffect(() => {
    if (token) {
      fetchProfile()
      if (provinceOfRegion) {
        fetchAddress()
      }
    }
    fetchCart()
  }, [token, refetch, provinceOfRegion])
  const fieldsWithClid = Object.keys(router.query).filter(key =>
    key.includes('clid')
  )
  const getSourceClid = () => {
    if (fieldsWithClid.length > 0) {
      switch (fieldsWithClid[0]) {
        case 'fbclid':
          return 'Facebook'
        case 'twclid':
          return 'Twitter'
        case 'ttclid':
          return 'Tiktok'
        case 'gclid':
          return 'Google Ads'
        case 'zaclid':
          return 'Zalo Ads'

        default:
          return fieldsWithClid[0]
      }
    } else {
      return ''
    }
  }

  const sourceClid = getSourceClid()

  const checkVisitor = async () => {
    const data = {
      ip: clientIP,
      branchCode: router?.query?.tt || null,
      staff: router?.query?.nv || null,
      program: router?.query?.ct || null,
      utm_campaign: router?.query?.utm_campaign || null,
      source: router?.query?.utm_source || sourceClid || null,
      path: router?.asPath,
    }

    const res = await api(`api/access`, {
      method: 'POST',
      baseURL: process.env.NEXT_PUBLIC_LOG_API_URL,
      data: data,
    })
    if (res && !(res instanceof Error)) {
      console.log(res)
    }
    return
  }
  const getListStore = async (
    query: {
      districtCode?: string
      provinceCode?: string
      productId?: string
    } = {}
  ) => {
    let params = null
    if (!isEmpty(query) && query) {
      params = convertQueryParam(query as QueryParam)
    }
    setGlobalLoading(true)
    const res = await api(`api/store?${params || ''}`, {
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_ECOMMERCE_API_URL,
    })
    setGlobalLoading(false)
    if (res && !(res instanceof Error)) {
      setStoreList(res.data)
      return
    }
  }
  const getListBranch = async (
    query: {
      districtCode?: string
      provinceCode?: string
      productId?: string
    } = {}
  ) => {
    let params = null
    if (!isEmpty(query) && query) {
      params = convertQueryParam(query as QueryParam)
    }
    setGlobalLoading(true)
    const res = await api(`api/branch?${params || ''}`, {
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_IAM_API_URL,
    })
    setGlobalLoading(false)
    if (res && !(res instanceof Error)) {
      const data = res.data.filter(
        (e: { saleRegionCode: string | boolean | null | undefined }) =>
          e.saleRegionCode
      )
      setListBranch(data)
      return
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const visitor = localStorage?.getItem('visitor')
      if (visitor != clientIP) {
        localStorage?.removeItem('visitor')
      }
      if (
        !visitor &&
        clientIP &&
        (router?.query?.tt ||
          router?.query?.ct ||
          router?.query?.nv ||
          sourceClid ||
          router?.query?.utm_source ||
          router?.query?.utm_term ||
          router?.query?.utm_campaign ||
          router?.query?.utm_medium)
      ) {
        checkVisitor()
        localStorage?.setItem('visitor', clientIP)
      }
    }
  }, [clientIP])
  useEffect(() => {
    if (token) {
      getShippingAddress().then(res => {
        // check điều kiện lấy danh sách nằm trong các vùng bán hàng
        const checkRegionAvailable = res?.filter(e => e.saleRegionCode)
        setListAddress(checkRegionAvailable as UserAddress[])
      })
    }
    setRefetch(false)
  }, [refetch, token, router.query.shippingAddress])
  useEffect(() => {
    if (router?.query?.shippingAddress)
      getShippingAddressDetail(router?.query?.shippingAddress as string).then(
        res => {
          if (res?.provinceCode == currentRegion) {
            setUserAddress(res as UserAddress | null)
          }
        }
      )
    setRefetch(false)
  }, [router, refetch])
  return {
    user,
    cart,
    setCart,
    token,
    storeList,
    setToken,
    setUser,
    logout,
    remember,
    setRemember,
    setDestinationCode,
    destinationCode,
    fetchProfile,
    fetchCart,
    channel,
    setChannel,
    getGuessTokenCart,
    installment,
    getListStore,
    getListBranch,
    setInstallment,
    storeSelected,
    setAddress,
    address,
    fetchAddress,
    setStoreSelected,
    userAddress,
    setUserAddress,
    fetchPopup,
    listAddress,
    setListAddress,
    listAddressByRegion,
    setListAddressByRegion,
    popupMain,
    isOpenAddAddress,
    isOpenListAddress,
    setIsOpenListAddress,
    setIsOpenAddAddress,
    refetch,
    setRefetch,
    listAddressReduce,
    globalLoading,
    errorAuth,
    setErrorAuth,
    clientIP,
    accessToken,
    setAccessToken,
    setVoucherApply,
    voucherApply,
    listBranch,
    setListBranch,
  }
}

export const [AuthProvider, useAuth] = constate(Container)
