export enum Urls {
  FAQ = '/faq',
  CONTACT_US = '/contact-us',
  PRIVACY_POLICY = '/policy',
}

export const urlsDetail = (id: string) => {
  return {
    MY_SUBMISSIONS_DETAIL: `${Urls.FAQ}/${id}`,
  }
}

export const B2C_URL = process.env.NEXT_PUBLIC_URL_WEBSITE
export const B2B_URL = process.env.NEXT_PUBLIC_URL_WEBSITE_B2B
