import { useEffect, useState } from 'react'

export const useLocalStorage = <T>(
  storageKey: string,
  initialValue: T
): [T, (newValue: T) => void] => {
  const [value, setValue] = useState<T>(initialValue)

  const setValueHandler = (newValue: T) => {
    if (typeof window !== 'undefined' && window.localStorage) {
      setValue(newValue)
      localStorage?.setItem(storageKey, JSON.stringify(newValue))
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const currentItem = localStorage?.getItem(storageKey)
      if (currentItem && currentItem !== 'undefined') {
        const parsedItem = JSON.parse(currentItem)
        setValue(parsedItem)
      }
    }
  }, [storageKey])

  return [value, setValueHandler]
}
