export interface ErrorMessage {
  en: string
  vi: string
}

export interface ErrorMessages {
  [key: string]: ErrorMessage
}

export const OnePayResponseCode: ErrorMessages = {
  '0': {
    en: 'Transaction is successful',
    vi: 'Giao dịch thành công',
  },
  '1': {
    en: 'This transaction has been declined by issuer bank or card have been not registered online payment services. Please contact your bank for further clarification.',
    vi: 'Ngân hàng phát hành thẻ không cấp phép cho giao dịch hoặc thẻ chưa được kích hoạt dịch vụ thanh toán trên Internet. Vui lòng liên hệ ngân hàng theo số điện thoại sau mặt thẻ được hỗ trợ chi tiết.',
  },
  '2': {
    en: 'This transaction has been declined by issuer bank. Please contact your bank for further clarification.',
    vi: 'Ngân hàng phát hành thẻ từ chối cấp phép cho giao dịch. Vui lòng liên hệ ngân hàng theo số điện thoại sau mặt thẻ để biết chính xác nguyên nhân Ngân hàng từ chối.',
  },
  '3': {
    en: 'Payment gateway did not received payment result from Issuer bank. Please contact your bank for details and try again.',
    vi: 'Cổng thanh toán không nhận được kết quả trả về từ ngân hàng phát hành thẻ. Vui lòng liên hệ với ngân hàng theo số điện thoại sau mặt thẻ để biết chính xác trạng thái giao dịch và thực hiện thanh toán lại',
  },
  '4': {
    en: 'Your card is expired or You have entered incorrect expired date. Please check and try again.',
    vi: 'Thẻ hết hạn sử dụng hoặc nhập sai thông tin tháng/ năm hết hạn của thẻ. Vui lòng kiểm tra lại thông tin và thanh toán lại',
  },
  '5': {
    en: 'This transaction cannot be processed due to insufficient funds. Please try another card.',
    vi: 'Thẻ không đủ hạn mức hoặc tài khoản không đủ số dư để thanh toán. Vui lòng kiểm tra lại thông tin và thanh toán lại',
  },
  '6': {
    en: 'An error was encountered while processing your transaction. Please contact your bank for further clarification.',
    vi: 'Quá trình xử lý giao dịch phát sinh lỗi từ ngân hàng phát hành thẻ. Vui lòng liên hệ ngân hàng theo số điện thoại sau mặt thẻ được hỗ trợ chi tiết.',
  },
  '7': {
    en: 'An error was encountered while processing your transaction. Please contact your bank for further clarification.',
    vi: 'Đã có lỗi phát sinh trong quá trình xử lý giao dịch. Vui lòng thực hiện thanh toán lại.',
  },
  '8': {
    en: 'Ecommerce transaction is not supported for this card. Please contact your issuer bank or try another card.',
    vi: 'Ngân hàng phát hành thẻ không hỗ trợ giao dịch thanh toán trên Internet. Vui lòng sử dụng thẻ của Ngân hàng khác để thực hiện thanh toán.',
  },
  '9': {
    en: 'This transaction has been declined by issuer bank. Please contact your bank for further clarification.',
    vi: 'Ngân hàng phát hành thẻ từ chối giao dịch. Vui lòng liên hệ ngân hàng theo số điện thoại sau mặt thẻ được hỗ trợ chi tiết.',
  },
  '99': {
    en: 'The transaction has been cancelled by card holder. Please try again.',
    vi: 'Người dùng hủy giao dịch',
  },
  B: {
    en: 'The card used in this transaction is not authorized 3D-Secure complete. Please contact your bank for further clarification.',
    vi: 'Không xác thực được 3D-Secure. Vui lòng liên hệ ngân hàng theo số điện thoại sau mặt thẻ được hỗ trợ chi tiết.',
  },
  E: {
    en: 'You have entered wrong CSC or Issuer Bank declided transaction. Please contact your bank for further clarification.',
    vi: 'Nhập sai CSC (Card Security Card) hoặc ngân hàng từ chối cấp phép cho giao dịch. Vui lòng liên hệ ngân hàng theo số điện thoại sau mặt thẻ được hỗ trợ chi tiết.',
  },
  F: {
    en: 'Due to 3D Secure Authentication Failed. Please contact your bank for further clarification.',
    vi: 'Không xác thực được 3D-Secure. Vui lòng liên hệ ngân hàng theo số điện thoại sau mặt thẻ được hỗ trợ chi tiết.',
  },
  Z: {
    en: 'Transaction restricted due to OFD’s policies. Please contact us for details',
    vi: 'Vi phạm quy định của hệ thống. Vui lòng liên hệ với chúng tôi để được hỗ trợ',
  },
  Other: {
    en: 'Please contact us for details',
    vi: 'Vui lòng liên hệ với chúng tôi để được hỗ trợ',
  },
}
