import { useAuth } from '@/containers'
import { getTranslate } from '@/utils/api-interceptors'
import { Dialog, Transition } from '@headlessui/react'
import { getCookie, setCookie } from 'cookies-next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { Fragment, useEffect, useState } from 'react'
import ImageOptimize from '../ImageOptimize'
import { IconClose } from '../Popup/PopupFrame'
export function PopupMain() {
  const [isOpen, setIsOpen] = useState(false)
  const { popupMain } = useAuth()
  const router = useRouter()
  function closeModal() {
    if (popupMain?.countdown) {
      return
    }
    setIsOpen(false)
  }
  const ignorePage = [
    '/checkout/payment',
    '/checkout/cart',
    '/login',
    '/register',
    '/checkout/confirm',
    '/checkout/installment',
    '/orders',
    '/search-order',
  ]
  useEffect(() => {
    const checkModal = getCookie('modalSection')
    if (checkModal == undefined && localStorage) {
      localStorage?.removeItem('modalMain')
    }
    if (typeof window !== 'undefined' && popupMain?.items?.length) {
      const id = localStorage?.getItem('modalMain')
      if (id !== popupMain?._id && !ignorePage.includes(router.route)) {
        localStorage?.setItem('modalMain', popupMain ? popupMain._id : '')
        setIsOpen(true)
        setCookie('modalSection', true)
        if (popupMain.countdown) {
          setTimeout(() => {
            setIsOpen(false)
          }, Number(popupMain.countdown) * 1000 || 3000)
        }
      }
    }
  }, [popupMain])
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as='div' className='relative z-[9999]' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bottom-0 left-0 bg-dark-900/20 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='h-full w-full max-w-[800px] transform overflow-hidden rounded-2xl p-6 text-left align-middle transition-all'>
                <div className='relative'>
                  {!popupMain?.countdown && (
                    <button
                      onClick={closeModal}
                      className='absolute right-0 top-0 z-10 cursor-pointer overflow-hidden rounded-full bg-dark-900/50 p-2 outline-none'
                    >
                      <IconClose />
                    </button>
                  )}
                  <Link
                    href={popupMain?.items[0]?.link || ''}
                    onClick={() => {
                      setIsOpen(false)
                    }}
                    className='block outline-none'
                  >
                    <ImageOptimize
                      src={popupMain?.items[0]?.image || ''}
                      width={400}
                      height={500}
                      className='w-full outline-none'
                      alt={getTranslate(popupMain?.items[0]?.name)}
                    />
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default PopupMain
