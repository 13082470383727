import { useNotification } from '@/containers'
import type { ImageProps } from 'next/image'
import Image from 'next/image'

const ImageOptimize = ({
  alt,
  thumbnail = false,
  ...rest
}: ImageProps & {
  alt: string
  thumbnail?: boolean
  lazyLoad?: boolean
}) => {
  const { systemConfig } = useNotification()
  const qtt =
    systemConfig?.imageQlt && systemConfig?.imageQlt != ''
      ? systemConfig?.imageQlt
      : 80
  const width =
    systemConfig?.imageThumbWidthDesktop &&
    systemConfig?.imageThumbWidthDesktop != ''
      ? systemConfig?.imageThumbWidthDesktop
      : 480
  const thumb = thumbnail ? `&width=${width}` : ''

  return (
    <Image
      {...rest}
      className={`${rest.className} imgOtm`}
      alt={alt || systemConfig?.websiteBrandName || ''}
      width={rest.width ? rest.width : '0'}
      height={rest.height ? rest.height : '0'}
      sizes='100%'
      src={
        rest.src
          ? rest.src + '?format=webp&quality=' + qtt + thumb
          : systemConfig?.imagePlaceholder ||
            process.env.NEXT_PUBLIC_IMAGE_PLACEHOLDER ||
            ''
      }
      loading={rest.priority ? 'eager' : 'lazy'}
    />
  )
}
export default ImageOptimize
