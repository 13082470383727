/* eslint-disable @typescript-eslint/no-explicit-any */
import { type FlashSale } from '@/types'
import constate from 'constate'
import { useEffect, useReducer } from 'react'

type Props = {
  flashSale: FlashSale | null
}

const flashSaleReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'DELETE_PRODUCT':
      return {
        ...state,
        products:
          state?.products?.filter(
            (e: any) => e._id !== action.payload.productId
          ) || [],
      }
    case 'UPDATE_PRODUCT':
      return action.payload
    default:
      return state
  }
}
const Container = (props: Props) => {
  const [flashSale, dispatch] = useReducer(flashSaleReducer, props.flashSale)

  useEffect(() => {
    if (props.flashSale)
      dispatch({
        type: 'UPDATE_PRODUCT',
        payload: props.flashSale,
      })
  }, [props.flashSale])
  return { flashSale, dispatch }
}

export const [FlashSaleProvider, useFlashSale] = constate(Container)
