var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f3-I7SM6jRi3wwNdTpcGC"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://877262df69806505de27fdb90b7ba94b@o4505792581140480.ingest.sentry.io/4505792602832896',

  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.01,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    new Sentry.Replay({
      // Additional Replay configuration goes in here, for example:
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    'window.webkit.messageHandlers',
    'avast',
    'file://',
    'translate.goog',
    'zaloJSV2',
    "Can't find variable: _AutofillCallbackHandler",
    'subiz have already initialized',
    'The operation is insecure.',
    'window.SafeDKWebAppInterface.interceptXmlHttpRequest is not a function',
    'You must provide either an anchor (typically render it inside a <Marker>) or a position props for <InfoWindow>.',
    "Identifier 'originalPrompt' has already been declared",
    "Failed to read the 'localStorage' property from 'Window': Access is denied for this document.",
    "Failed to execute 'drawImage' on 'CanvasRenderingContext2D': The HTMLImageElement provided is in the 'broken' state.",
  ],
  sampleRate: 0.25,
  environment:
    process.env.NEXT_PUBLIC_ENVIRONMENT === 'prod'
      ? 'production'
      : process.env.NEXT_PUBLIC_ENVIRONMENT,
})
