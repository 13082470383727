export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fbq: any
  }
}
export const pageView = () => {
  const isBrowser = () => typeof window !== 'undefined'
  if (isBrowser() && typeof window.fbq === 'function') {
    console.log('Facebook Pixel initialized. Calling pageView.')
    window?.fbq('track', 'PageView')
  } else {
    console.error(
      'Facebook Pixel not initialized or window.fbq is not a function.'
    )
  }
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const fbqEvent = (name: string, options = {}) => {
  const isBrowser = () => typeof window !== 'undefined'
  if (isBrowser() && window?.fbq) {
    window.fbq('track', name, options)
  }
}
export const Event = (name: string, options = {}) => {
  const isBrowser = () => typeof window !== 'undefined'
  if (isBrowser() && window?.fbq) {
    window.fbq('track', name, options)
  }
}
