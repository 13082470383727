/* eslint-disable @typescript-eslint/no-explicit-any */
import { getCookie, setCookie } from 'cookies-next'
import { useEffect, useState } from 'react'

export const useCookie1Y = <T>(
  storageKey: string,
  initialValue: T
): [T, (newValue: T) => void] => {
  const getCookieData = () => {
    return (getCookie(storageKey) as any) || initialValue
  }
  const [value, setValue] = useState<T>(getCookieData())

  const setValueHandler = (newValue: T) => {
    setValue(newValue)
    setCookie(storageKey, newValue, {
      maxAge: 2147483647,
    })
  }

  useEffect(() => {
    const currentItem = getCookie(storageKey)
    if (currentItem && currentItem !== 'undefined') {
      setValue(currentItem as any)
    }
  }, [storageKey])

  return [value, setValueHandler]
}
